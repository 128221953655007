<template>
  <div>
    <SiteHeader></SiteHeader>
    <div class="topbar text-center">
      <strong class="fs-1"
        >{{ $t("Please submit an") }}
        <a
          class="loginbtn text-primary text-decoration-underline"
          @click="toRegister"
          >{{ $t("application") }}</a
        >
        {{ $t("to get access items and pricing") }}
      </strong>
    </div>
    <div class="container mt-5 mb-5">
      <div class="login">
        <form class="form-login" method="post">
          <div class="jumbotron psrel mb-4">
            <h4 class="fs-4">{{ $t("Login") }}</h4>
            <hr class="my-4" />
          </div>
          <div class="input-group mb-4">
            <span class="input-group-text"> {{ $t("user") }} </span>
            <input
              type="text"
              class="form-control ajax_username"
              name="username"
              ref="username"
              v-model="form.username"
              @blur="validateInput"
              form-verify="required|email"
            />
            <p class="feedback ps-2">{{ $t("Enter your email address") }}</p>
          </div>

          <div class="input-group mb-4">
            <span class="input-group-text">{{ $t("Password") }}</span>
            <input
              type="password"
              class="form-control"
              v-model="form.password"
              @blur="validateInput"
              name="password"
              ref="password"
              form-verify="required|stringCheck"
            />
            <p class="feedback ps-2">
              <a
                class="text-secondary text-decoration-underline"
                @click="toForget"
              ></a>
            </p>
            <p class="ps-2">
              <a
                class="text-primary text-decoration-underline"
                @click="toForget"
                >{{ $t("Lost your password") }}</a
              >
            </p>
          </div>
          <div class="d-flex flex-row align-items-center">
            <span class="pe-4 ps-0"
              ><button
                @click="loginSubmit"
                type="button"
                class="btn btn-primary ajax-login-submit"
              >
                {{ $t("Log in") }}
              </button></span
            >
            <span class="pe-4 form-check form-switch fs-6">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                id="remember"
                v-model="form.remember"
              />
              <label class="form-check-label" for="remember">{{
                $t("Remember Me")
              }}</label>
            </span>
            <span class="pe-4">
              <a class="text-primary fs-6" @click="toRegister">{{
                $t("Open Account")
              }}</a>
            </span>
          </div>
        </form>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import SiteHeader from "@/components/website/SiteHeader.vue";

import Foot from "@/components/website/Foot.vue";

export default {
  name: "PcWebsiteIndex",

  components: {
    SiteHeader,
    Foot,
  },

  data() {
    return {
      secretKey: "12321312321",
      form: {
        username: "",
        password: "",
        remember: false,
      },

      fieldErrors: {
        username: "",
        password: "",
      },
      ruleField: ["username", "password"],
    };
  },

  watch: {},
  mounted() {
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    // console.log('username',username,'password',username)

    if (username) {
      this.form.username = this.decrypt(username);
    } else {
      this.form.username = "";
    }

    if (password) {
      this.form.password = this.decrypt(password);
    } else {
      this.form.password = "";
    }
  },
  methods: {
    // 加密函数
    encrypt(message) {
      let encrypted = "";
      for (let i = 0; i < message.length; i++) {
        encrypted += String.fromCharCode(
          message.charCodeAt(i) ^
            this.secretKey.charCodeAt(i % this.secretKey.length)
        );
      }
      return encrypted;
    },

    // 解密函数
    decrypt(encryptedMessage) {
      return this.encrypt(encryptedMessage); // 因为是基于异或运算的简单加密，所以加密和解密过程相同
    },

    validateInput(event) {
      const field = event.target.getAttribute("name");
      let strings = event.target.getAttribute("form-verify");
      const rules = strings.split("|");

      // console.log(event, field, rules)
      for (const rule of rules) {
        if (!this.validateField(field, rule)) {
          this.fieldErrors[field] = this.$t(`verify-${rule}`);
          break;
        } else {
          delete this.fieldErrors[field];
        }
      }

      // 更新界面反馈
      // 示例：在DOM中找到对应的反馈元素并设置其内容
      const feedbackElement =
        event.target.parentNode.querySelector(".feedback");
      if (feedbackElement.classList.contains("feedback")) {
        feedbackElement.textContent = this.fieldErrors[field] || "";
        // feedbackElement
        if (this.fieldErrors[field]) {
          feedbackElement.style.color = "red";
        } else {
          feedbackElement.style.color = ""; // 恢复到默认颜色
        }
      }
    },

    validateField(field, rule) {
      // 根据rule执行相应的验证逻辑，并返回验证结果（true或false）
      // 示例：
      switch (rule) {
        case "required":
          return this.form[field] !== "";
        case "email":
          const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          const phoneRegex = /^1[3-9]\d{9}$/; // 示例为中国手机号正则表达式
          if (this.isZh) {
            return phoneRegex.test(this.form[field]);
          } else {
            return pattern.test(this.form[field]);
          }

        case "stringCheck": // 假设这是一个自定义字符串检查规则
          return (
            typeof this.form[field] === "string" &&
            this.form[field].length > 0 &&
            this.form[field] === this.form.password
          );
      }
    },
    clientTypeChange(option) {
      // console.log('change', this.form.clientType)
    },
    loginSubmit() {
      //整体验证
      if (this.allFieldsVerify()) {
        var that = this;
        //下面是验证通过后的登录的逻辑代码 ==参数需要根据接口对比后确定
        const userinfo = this.form;
        this.$store.dispatch("user/login", userinfo).then((res) => {
          //登录成功
          //判断是否需要记住我
          if (that.form.remember) {
            localStorage.setItem("username", this.encrypt(that.form.username));
            localStorage.setItem("password", this.encrypt(that.form.password));
          }
          this.$message({
            message: this.$t("Login Success"),
            type: "success",
          });
          this.$router.push("/account");
        });
      } else {
        // console.log('error')
      }
    },

    allFieldsVerify() {
      let pass = true;
      this.ruleField.map((item) => {
        let strings = this.$refs[item].getAttribute("form-verify");
        const rules = strings.split("|");

        // console.log('rules', rules, 'item', item)

        for (const rule of rules) {
          if (!this.validateField(item, rule)) {
            pass = false;
            this.fieldErrors[item] = this.$t(`verify-${rule}`);
            break;
          } else {
            delete this.fieldErrors[item];
          }
        }
        // 更新界面反馈
        // 示例：在DOM中找到对应的反馈元素并设置其内容
        const feedbackElement =
          this.$refs[item].parentNode.querySelector(".feedback");
        if (feedbackElement.classList.contains("feedback")) {
          feedbackElement.textContent = this.fieldErrors[item] || "";
          if (this.fieldErrors[item]) {
            feedbackElement.style.color = "red";
          } else {
            feedbackElement.style.color = ""; // 恢复到默认颜色
          }
        }
      });
      return pass;
    },

    toForget() {
      this.$router.push("/forget");
    },
    toRegister() {
      this.$router.push("/register");
    },

    tologin() {
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.login {
  min-height: 600px;
  display: flex;
  align-items: center;
  background-image: url("/svg/member-login.svg");
  background-repeat: no-repeat;
  background-position: right center;

  form {
    width: 50%;
    border: 1px solid var(--el-border-color);
    border-radius: 5px;
    padding: 20px;
  }
}
</style>
